.slider-container{

   /* height: 100%; */
/* height: 90vh; */
    margin: 25px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    width: 100%;
height: 100%;
   
flex-direction: column;
}
.btn{
    position: absolute;
    top: 50%;
    font-size: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.2s;
    cursor: pointer;
    user-select: none;
    z-index: 3;
    color: #f79000;
}
.btn:hover{
    transform: scale(0.9);
    transition: 0.2s;
}
.btn:active{
    transform: scale(1);
    transition: 2s;
}
.left-button{
 left: 0;
}
.rigth-button{
  right: 0;

}
 .image{
height: 100%;
width: 100%;
  object-fit: contain;
  margin: 0 auto;
  text-align: center;
  
 } 
 .slide {
   /* opacity: 0.8; */
   transition: 1s;
   text-align: left;
   width: 80%;
   /* transform: translateX(-100vw); */
   
  }
  .slide::before{
    inset: 0;
    content: "";
    background-color: rgba(0, 0, 0, .16);
    position: absolute;
    /* z-index: 1; */
    height: 100%;
    height: 100%;
  }
  .text-box-image-slider{
    position: absolute;
    top: 30%;
z-index: 1;
    width: 50%;
left: 15%;
right: 0;
/* background-color: #f79000; */
padding: 5px 10px;
animation: scale-in-left .5s linear;

  }

  @-webkit-keyframes scale-in-left{0%{-webkit-transform:scale(0);transform:scale(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:0 50%;transform-origin:0 50%;opacity:1}}@keyframes scale-in-left{0%{-webkit-transform:scale(0);transform:scale(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:0 50%;transform-origin:0 50%;opacity:1}}
  

  .slide.active {

   opacity: 1;
   transition: .5s;
   text-align: center;
  }
  .dots-container{
    display: flex;
    user-select: none;

  }
  .dot{
font-size: 32px;
transition: 1s;
user-select: contain;
cursor: pointer;
color: darkgray;
z-index: 2;
  }
  .dot-active{
    color: hsl(209, 23%, 22%);
    transition: 1s;
    transform: scale(1.2);

  }
  @media screen and (max-width: 880px) {
    
    .image{
      width: 100%;
      height: 100%;
    }
    .text-box-image-slider{
      position: relative;
      left: 0;
      width: 100%;
      padding: 5px 10px;
    
      min-height: 314px;
    }

  }