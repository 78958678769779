.preguntas-frecuentes{
    margin: 0 auto;
    /* max-width: 800px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 85vh;
    text-align: center;
  color: #f79000;
}.preguntas-frecuentes-h2 {
    color: #f79000
}
.preguntas-frecuentes p {
  padding: 5px 10px;
}
.area-preguntas-frecuentes{
  background-color: rgba(0, 0, 34, .9);

/* background-color: #fff; */
  }