.inicio-container{
    display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
margin: 0 0;
min-height: 85vh;
background-position: -350px;
margin-bottom: 50px;
}
.inicio-text-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top:20px;
    z-index: 1;

 
    /* min-width: 350px;
    max-width: 600px;
    min-height: 350px; */

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}
.inicio-text-container h5 {
    font-size: 25px;
    text-align: center;
}
.video-container::before{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, .45);
    z-index: 1;
}
.video-container video{
    z-index: 0;
}
.main-title{
    font-size: 55px;
    color:#fff;
}
.main-text{
    color:#fff;

    margin: 0 auto;
    	text-align: center;
    font-size: 22px;
 
}
#mas-informacion{
    padding: 15px 50px;
    font-size: 25px;
    background-color: transparent;
    border: 2px solid #fff;
    transition: 0.3s;
}

#mas-informacion:hover{
    background-color: #efef;
    color: #000;
    transform: scale(0.95);
}

#mas-informacion:active {
    transform: scale(1.1);
}
.btn-whatsapp{
    position: fixed;
    bottom: 30px;
    line-height: 55px;
    height: 55px;
    width: 55px;
    right: 30px;
    color: #fff;
    background-color: #0df053;
    font-size: 30px;
    display: grid;
    place-content: center;
       border-radius: 50px;
       z-index: 100;
       box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
    cursor: pointer;
    
    }
    .btn-whatsapp:hover{
        color:#0df053;
        background-color: #fff;
    
    }