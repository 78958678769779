.productos {
  /* margin: 5px 10px; */

min-height: 90vh;
object-fit: cover;
padding: 20px 0px;
display: flex;
flex-direction: column;
background-color: rgba(0, 0, 34, .9);
color: #f79000;

justify-content: space-around;
}
.productos h2{
  text-align: center;
  color: #f79000;
  margin: 40px 0;
}
.productos p {
  color: #ffff;
  line-height: 1.8;
  letter-spacing: 0px;
  word-spacing: 5px;
  text-align: center;
  margin-top: 20px;
  padding: 5px 10px;

}

#conoce-nuestro-trabajo{
  margin: 0 auto;
  text-align: center;
  padding: 20px 30px;
  font-size: 25px;
  
}