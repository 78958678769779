.nav-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px ;
position: absolute;
top: 0;
width: 100%;
z-index: 3;
 justify-content: center;

}
.nav-item{
    text-decoration: none;
    color: #fff;
font-size: 22px;


}
.logo-img{
    width: 100px;
    margin-right: 40px;
    border-radius: 50%;
    mix-blend-mode: color-burn;
}
.nav{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.nav-item:hover{
    color: hsl(209, 23%, 22%);
}
   
.nav-btn{
    background-color: transparent;
    border: none;
    padding: 10px;
    display: none;
    color: #fff;
}

@media screen and (max-width: 768px) {
    
    .nav-btn{
        visibility: visible;
        display: block;
        font-size: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      
    }
    .nav-btn:hover{
        transition: 0.3s;
        border-radius: 50%;
        background-color: #000022;
    }
    .nav-btn:active{
        transition: 0.3s;
        border-radius: 50%;
        background-color: #000022;
        transform: scale(0.8);
    }
    .nav{
        display: none;
        position: fixed;
        top: -100vh;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        transition: 0.5s;
        height: 100%;
    }
    .responsive-nav{
  
        background-color: #bb3e03;
     
        height: 100vh;
        transition: 1s;
      transform: translateY(100vh);
      z-index: 2;

        


    }
    .nav-item{
        color: #fff;
    }
    .nav-close-btn{
        color:#fff;
    }
   .nav-close-btn:hover{
    border-radius: 50%;
    background-color:hsl(207, 26%, 17%) ;
    
}
.nav-close-btn:active{
    background-color: #495057;
}
}
.cta-button {
    background-color: #000022;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 4px;
    font-size: 22px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: rgba(0, 0, 20, 1)
    ;
  }
