.section-cards-products{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 34, .9) ;
}
.section-cards-products h2{
    margin-top: 50px;
    margin-bottom: 50px;
    color: #f79000;

}
.container-product{
    display: grid;
    grid-template-columns: repeat(auto-fit,
    minmax(300px,1fr));
    /* gap: 20px; */
    /* margin: 10px 20px; */
    margin-top: 50px;
 
    overflow: hidden;
    transition:  all 1.5s cubic-bezier(0,1,0,1);
justify-content: center;
margin: 0 auto;
}
@media screen and (min-width: 768px) {
    .container-product{
        width: 100%;
    }
}
.card-product{

border-radius: 8px;
/* width: 100%; */
cursor: pointer;
user-select: none;

}
.img-card-product-container{
    /* width: 100%; */
    height: 472px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 337px;
    position: relative;
    z-index: 0;
    transition: .5s;
}
.img-card-product-container:hover::before{
    position: absolute;
    content: "";
top: 0;
left: 0;
right: 0;
bottom: 0;
    background-color: rgba(255, 121, 0, .15);
    /* z-index: 1; */
}
.img-card-product{
    object-fit: cover;
    width: 100%;
    height: 505px;
    /* border-radius: 8px 8px 0 0; */
    transition: 0.5s;
    user-select: none;
    cursor: pointer;
    z-index: -1;
}

 .card-product:hover .button-picture, .card-product:hover .img-card-product{
transform: scale(1.1);
/* opacity: 1; */

}
.card-product:hover .title-card-product{
    opacity: 1;
}
.title-card-product{
   
    color: #fff;;
    position: relative; 
    top: 50%;
z-index: 0;
transition: 0.5s;
opacity: 0;

/* padding: 15px 10px; */

}


.button-picture{
    font-size: 32px;
    position: absolute;
    bottom: 0;
    right: 12px;
    color: #000022;
transition: 0.5s;

}
.card-product:hover .button-picture{
    color: #f79000;
    transform: scale(1.3);
}
.card-product:active .button-picture{
    color: #f79000;
    transform: scale(0.8);
}
