.contact-title{
  text-align: center;
display: block;
  
}
.contact-container {
    display: flex;
align-items: center;
justify-content: space-around;
flex-wrap: wrap;
padding: 20px;
/* background-color: #dee2e6; */
background:linear-gradient(rgba(0, 0, 34, .75),rgba(0, 0, 34, .75)) ,url("../../assets/images/1.jpeg");
background-size: cover;

  }
.contact-area-text{
  
  max-width: 550px;
  min-width: 250px;
  height: 600px;
border: 1px solid;
border-radius: 20px;
padding: 20px ;
display: flex;
flex-direction: column;
justify-content: space-around;
background-color: #fff;
margin: 20px 0;
border: 2px solid #fff;
align-items: center;

background-color: transparent;

color: #fff;
}
.contact-area-text div h2,.contact-area-form h2 {
  text-align: center;
  color:#f79000
}
.contact-area-form{
border: none;

  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  margin: 20px 0;
  max-width: 550px;
  min-width: 250px;
  width: 100%;
  height: 600px;
  border-radius: 20px;
padding: 20px ;
  display: flex;
  flex-direction: column;
justify-content: space-between;

}
.contact-area-form div{
  display: flex;
  flex-direction: column;
}

.contact-area-form div input{
  border-radius: 5px;
  outline: none;
  height: 45px;
  padding: 16px;
  background-color: rgba(0, 0, 34, .65);
  border: transparent;
color: #fff;
}

.contact-area-form div textarea{
  min-height: 120px;
  width: 100%;
  padding: 16px;
  outline: none;
  border-radius: 5px;
  background-color: rgba(0, 0, 34, .65);
border: transparent;
color: #fff;


}
.mensaje-click{
  display: none;
  visibility: none;
}
.wsp{
  padding: 10px .5em;
  background-color: #000022;
}

@media screen and (max-width: 768px) {
  .wsp{
  
    background-color: #000022;
    /* width: 80%; */
    font-size: 1em;

  }
  
}