.overlay{
    /* width: 100vw;
    height: 100vh; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0, 0, 0, .85) ;

    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contenedor-modal{
    /* width: 80vw;
    min-height: 95vh; */
    background-color: transparent;
    /* position: relative; */
    /* left: 0; */
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111,0.2) 0px 7px 29px 0px ;
    /* margin: 20px; */
    /* display: flex;
    justify-content: center;
    flex-direction: column;  */
    /* align-items: stretch; */
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;

}

.contenedor-boton-cerrar-modal{
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 3;

}
.boton-cerrar-modal {
display: flex;
align-items: center;
justify-content: center;
border: none;
background-color: transparent;
transition: 0.3 ease all;
font-size: 22px;

padding: 5px;
}
.boton-cerrar-modal span{
    color: #f79000;
    font-size: 55px;
}
.boton-cerrar-modal span:hover{
    color: rgba(255, 199, 0, 1);
 
}
.contenido-modal{
    /* max-height: 75vh; */
    /* min-height:200px;  */
    width: 100%;
    height: 100%;
     /* overflow-y: scroll;  */
    z-index: 2;

}
