footer{
    background-color: rgba(0, 0, 34, .9);
}
footer .container{
    padding: 30px 20px;
    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(120px ,1fr)
    );
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.footer-item{
 text-align: center;
}
footer .container img{
    cursor: pointer;
}
footer hr{
    height: 4px;
    background-color: #f79000;
    border: none;

}
footer div h4{
    cursor: pointer;
}
footer div h4 a{
    color: #fff;
}
footer div h4 a:hover{
    color: #efefef;
    text-decoration: underline;
}
footer div h4 a:active{
    color: #f79000;
}