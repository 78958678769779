.item{
    background-color: transparent;
    /* padding: 0 5px ; */
    border-radius: 2px;
    margin-bottom: 5px;
    max-width: 95%;
    min-width: 300px;
    margin: 0 auto;
}
.acordeon-title{
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    background-color: #fb8500;
    color: #fff;
    height: 70px;
    border: 1px solid #333;
}
 .acordeon-title h2 {
    font-size: 1.1em;
 }   
.acorden-content{
    
    overflow: hidden;
    max-height: 0;
    transition:  all 1s cubic-bezier(0,1,0,1);
    
}
.acorden-content p{
    padding:  0 20px;
    color:#fff;
    line-height: 1.8;
letter-spacing: 0px;
word-spacing: 5px;
text-align: center;

}

.active{
   
height: auto;
max-height: 9999px;
transition:  all 1s cubic-bezier(1,0,1,0);


 

}
