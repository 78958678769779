.slider2-container{

     margin: 0 auto;
     /* position: relative; */
     display: flex;

     align-items: center;
     justify-content: center;

     width: 100%;
     height: 100%;
  
 flex-direction: column;
 }
 .btn{
     position: absolute;
     top: 50%;
     font-size: 55px;
     display: flex;
     justify-content: center;
     align-items: center;
     overflow: hidden;
     transition: 0.2s;
     cursor: pointer;
     user-select: none;
     z-index: 3;
     color: #f79000;
 }
 .btn:hover{
     transform: scale(0.9);
     transition: 0.2s;
 }
 .btn:active{
     transform: scale(1);
     transition: 2s;
 }
 .left-button{
  left: 0;
 }
 .rigth-button{
   right: 0;
 
 }
  #image-slide{
 /* height: 100%; */
 max-height: 90vh;
/* width: fit-content;
 
   object-fit: contain; */

   
  } 
  .slide2 {
    /* opacity: 0.8; */
    transition: 1s;
    text-align: left;
    /* width: 80%; */
    /* transform: translateX(-100vw); */

   }



   .slide2.active2 {
 
    opacity: 1;
    transition: .5s;

 
   }
   